var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';

const isTypeField = value => value.type;

export const isCustomField = value => value.render;
export const isFormArray = value => value.configs && typeof value.configs === 'function';
export const isFormGroup = value => value.configs && Array.isArray(value.configs);

const TypeRenderHOC = item => props => {
  // eslint-disable-next-line react/destructuring-assignment
  const render = props.state.readonly ? item.render : item.formRender;
  return React.isValidElement(render) ? React.cloneElement(render, props) : React.createElement(render, props);
};

const handleField = (field, repo) => {
  var _a;

  if (isTypeField(field)) {
    const {
      type
    } = field,
          cfg = __rest(field, ["type"]);

    const item = (_a = repo.formItems) === null || _a === void 0 ? void 0 : _a[type];

    if (!item) {
      throw new Error(`表单项类型(type)\`${type}\`不存在！,请确认FormRegistry是否已注册该类型`);
    }

    return Object.assign(Object.assign({}, cfg), {
      render: TypeRenderHOC(item)
    });
  }

  if (isCustomField(field)) {
    return field;
  }

  if (isFormArray(field)) {
    const {
      configs
    } = field;
    return Object.assign(Object.assign({}, field), {
      configs: (operation, index) => configs(operation, index).map(config => handleField(config, repo))
    });
  }

  if (isFormGroup(field)) {
    const {
      configs
    } = field;
    return Object.assign(Object.assign({}, field), {
      configs: configs.map(config => handleField(config, repo))
    });
  }

  return field;
};

export default ((configs, repo) => {
  if (!Array.isArray(configs)) return [];
  return configs.map(field => handleField(field, repo));
});