import uuid2 from '../uuid';
export class FormArrayOperation {
  constructor(flux, path, initFields, update) {
    this.flux = flux;
    this.path = path;
    this._update = update;
    this._fields = initFields;
  }

  get fields() {
    return this._fields;
  }

  add(defaultValues = {}, insertIndex = this._fields.length) {
    const {
      length
    } = this._fields;

    this._fields.splice(insertIndex, 0, {
      key: uuid2(8, 10),
      name: insertIndex,
      defaultValues
    });

    if (this._fields.length !== insertIndex && this.path) {
      this.flux.arrayMove(this.path, length, insertIndex);
    }

    this._fields = this._fields.map((item, i) => Object.assign(Object.assign({}, item), {
      name: i
    }));

    this._update(this._fields);
  }

  remove(index) {
    const indexs = Array.isArray(index) ? index : [index];
    const {
      length
    } = this._fields;
    indexs.forEach(item => {
      if (this._fields[item]) {
        this._fields.splice(item, 1, null);

        if (this.path) {
          this.flux.arrayMove(this.path, item, length - 1);
        }
      }
    });
    this._fields = this._fields.filter(field => !!field);
    this._fields = this._fields.map((item, i) => Object.assign(Object.assign({}, item), {
      name: i
    }));

    this._update(this._fields);
  }

  move(from, to) {
    const swapField = this._fields[from];

    this._fields.splice(from, 1, null);

    this._fields.splice(to + 1, 0, swapField);

    if (this.path) {
      this.flux.arrayMove(this.path, from, to);
    }

    this._fields = this._fields.filter(field => !!field);
    this._fields = this._fields.map((item, i) => Object.assign(Object.assign({}, item), {
      name: i
    }));

    this._update(this._fields);
  }

}
export class FormArrayState {
  constructor({
    defaultValues,
    visible,
    readonly
  }) {
    this.dirty = false;
    this.valid = false;
    this.visible = false;
    this.readonly = false;
    this.errors = [];
    this.value = Array.isArray(defaultValues) ? defaultValues : [];
    this.visible = typeof visible === 'function' ? false : !!visible;
    this.readonly = typeof readonly === 'function' ? false : !!readonly;
  }

}