// 指定长度和基数
export default function uuid2(len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i; // eslint-disable-next-line no-param-reassign

  radix = radix || chars.length;

  if (len) {
    // Compact form
    // eslint-disable-next-line no-plusplus,no-bitwise
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    let r; // rfc4122 requires these characters
    // eslint-disable-next-line no-multi-assign

    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4'; // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    // eslint-disable-next-line no-plusplus

    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        // eslint-disable-next-line no-bitwise
        r = 0 | Math.random() * 16; // eslint-disable-next-line no-bitwise

        uuid[i] = chars[i === 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}