var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { useMemo, useState } from 'react';
import { FormArrayOperation } from '../core/views/form_array';
import { FormScopeProvider } from './context';
import { useFormState } from './use-form-state';
export const FormArray = props => {
  const {
    name,
    label,
    children,
    defaultValues
  } = props;

  const _a = useFormState(props, 'array'),
        _b = _a.state,
        {
    value
  } = _b,
        state = __rest(_b, ["value"]),
        {
    registry,
    flux,
    path
  } = _a;

  const [fields, setFields] = useState(defaultValues && defaultValues.length > 0 ? defaultValues.map((_item, index) => ({
    name: index,
    key: index.toString(),
    defaultValues: {}
  })) : [{
    name: 0,
    key: '0',
    defaultValues: {}
  }]);
  const [instance] = useState(() => new FormArrayOperation(flux, path, fields, setFields));
  const container = useMemo(() => typeof registry.arrayContainer === 'function' ? registry.arrayContainer : ({
    children: element
  }) => element, [registry.arrayContainer]);
  const content = useMemo(() => container(Object.assign(Object.assign({
    label
  }, state), {
    children: children === null || children === void 0 ? void 0 : children(fields, instance)
  }), fields, instance), // eslint-disable-next-line react-hooks/exhaustive-deps
  [state.readonly, state.visible, state.valid, state.errors, state.dirty, label, fields]);
  return React.createElement(FormScopeProvider, {
    name: name
  }, content);
};