var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormGlobalContext, FormRepoContext } from './context';
import FormContext, { transformConfigs, isFormArray, isFormGroup, isCustomField } from '../core';
import { FormArray } from './form-array';
import { FormGroup } from './form-group';
import { FormField } from './form-field';
export const Form = forwardRef(({
  readonly,
  configs: cfgs = [],
  defaultValues,
  onChange,
  children
}, ref) => {
  var _a;

  const registry = useContext(FormRepoContext);
  const [context] = useState(() => new FormContext(defaultValues, readonly));
  useImperativeHandle(ref, () => context.handles);
  const Container = (_a = registry.formContainer) !== null && _a !== void 0 ? _a : React.Fragment;
  useEffect(() => {
    if (readonly === undefined) return;
    context.flux.setReadonly(!!readonly); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonly]);
  useEffect(() => {
    if (onChange) {
      context.flux.unListener();
      context.flux.addListener((path, state) => {
        onChange({
          [path]: state
        }, context.flux.getStates());
      });
    }

    return () => {
      context.flux.unListener();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // eslint-disable-next-line no-shadow

  const render = useCallback(configs => configs.map((item, i) => {
    var _a, _b;

    if (isFormArray(item)) {
      return React.createElement(FormArray, {
        key: (_a = item.name) !== null && _a !== void 0 ? _a : i,
        name: item.name,
        readonly: readonly
      }, (fields, operation) => {
        return fields.map((field, index) => React.createElement(FormGroup, Object.assign({}, field, {
          readonly: readonly
        }), render(item.configs(operation, index))));
      });
    }

    if (isFormGroup(item)) {
      return React.createElement(FormGroup, {
        key: (_b = item.name) !== null && _b !== void 0 ? _b : i,
        name: item.name,
        readonly: readonly
      }, render(item.configs));
    }

    if (isCustomField(item)) {
      const {
        name,
        required,
        deps,
        props,
        rules,
        value,
        label,
        transform,
        asyncData,
        readonly: itemReadonly,
        render: itemRender,
        visible
      } = item,
            extra = __rest(item, ["name", "required", "deps", "props", "rules", "value", "label", "transform", "asyncData", "readonly", "render", "visible"]);

      return React.createElement(FormField, {
        key: name !== null && name !== void 0 ? name : i,
        name: name !== null && name !== void 0 ? name : i.toString(),
        required: required,
        deps: deps,
        rules: rules,
        props: props,
        transform: transform,
        defaultValue: value,
        label: label,
        visible: visible,
        asyncData: asyncData,
        readonly: readonly || itemReadonly,
        externalConfig: extra
      }, itemRender);
    }

    return null;
  }), [readonly]);
  const content = useMemo(() => render(transformConfigs(cfgs, registry)), // eslint-disable-next-line react-hooks/exhaustive-deps
  [cfgs, registry, render, readonly]);
  return React.createElement(FormGlobalContext.Provider, {
    value: context
  }, React.createElement(Container, null, content, children));
});