import React, { useCallback, useMemo } from 'react';
import { useFormState } from './use-form-state';
/**
 * - deps
 */

export const FormField = props => {
  var _a;

  const {
    name,
    label,
    children,
    required,
    externalConfig
  } = props;
  const {
    state,
    path,
    flux,
    handles,
    registry
  } = useFormState(props, 'field');
  const containerProps = useMemo(() => registry.fieldContainer ? Object.assign({
    label,
    required,
    dirty: state.dirty,
    valid: state.valid,
    readonly: state.readonly,
    errors: state.errors,
    name
  }, externalConfig) : undefined, // eslint-disable-next-line react-hooks/exhaustive-deps
  [state, path, externalConfig]);
  const Container = (_a = registry.fieldContainer) !== null && _a !== void 0 ? _a : React.Fragment;
  const handleChange = useCallback(value => {
    if (!path) return;
    flux.changeValue(path, value);
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [path]);
  const handleBlur = useCallback(() => {
    if (!state.touched) {
      flux.setStateStatus(path, {
        touched: true
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [path, state.touched]);
  const itemProps = useMemo(() => {
    var _a;

    return path ? Object.assign(Object.assign({}, state.props), {
      onChange: handleChange,
      onBlur: handleBlur,
      state,
      value: (_a = state.value) !== null && _a !== void 0 ? _a : ''
    }) : undefined;
  }, [path, state, handleChange, handleBlur]);
  const formItem = useMemo(() => {
    if (!children) return children; // eslint-disable-next-line no-nested-ternary

    return React.isValidElement(children) ? React.cloneElement(children, itemProps) : typeof children === 'function' ? children(itemProps, handles) : React.createElement(children, itemProps); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, itemProps]);
  return state.visible || !path ? React.createElement(Container, Object.assign({}, containerProps), formItem) : null;
};