import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormGlobalContext, FormRepoContext, FormScopeContext } from './context';
export const useFormState = (props, type) => {
  const isInit = useRef(true);
  const registry = useContext(FormRepoContext);
  const {
    flux,
    handles
  } = useContext(FormGlobalContext);
  const {
    path: names = []
  } = useContext(FormScopeContext);
  const path = useMemo(() => props.name !== undefined && props.name !== null && props.name !== '' && names.every(na => !!na) ? names.concat(props.name).join('.') : null, [names, props.name]);
  const [state, setState] = useState(() => flux.newForm(type, path, props, registry, handles));
  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }

    if (!path) return;
    flux.updateProps(type, path, props, registry, handles); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rules, props.required, props.readonly, props.asyncData, props.label, props.name, props.visible, props.deps]);
  useEffect(() => {
    if (!path) return null;
    flux.addListener(path, (npath, changeState) => {
      if (path !== npath) return;
      setState(() => changeState);
    }); // eslint-disable-next-line consistent-return

    return () => {
      flux.unListener(path);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  return {
    path,
    flux,
    handles,
    registry,
    state,
    setState
  };
};