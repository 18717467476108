import set from 'lodash.set';
export default (flux => {
  return {
    getPathValue(path) {
      const states = flux.getStates(path);
      if (!states) return states;
      return Object.keys(states).reduce((values, npath) => {
        const cpath = npath.replace(new RegExp(`^${path !== null && path !== void 0 ? path : ''}\\.?`), '');

        if (cpath) {
          if (states[npath].visible) {
            const {
              value
            } = states[npath];
            const tValue = flux.getTransformValue(npath, value); // eslint-disable-next-line no-unused-expressions

            tValue ? Object.assign(values, tValue) : set(values, cpath, value);
          }

          return values;
        }

        return states[npath].visible ? states[npath].value : null;
      }, {});
    },

    getPathValid(path) {
      const states = flux.getStates(path);
      return states ? Object.keys(states).every(npath => !states[npath].visible || states[npath].valid) : undefined;
    },

    getPathDirty(path) {
      const states = flux.getStates(path);
      return states ? Object.keys(states).some(npath => !states[npath].visible || states[npath].dirty) : undefined;
    },

    setFieldProps(path, props) {
      flux.setFieldProps(path, {
        props
      });
    },

    setPathDirty() {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments.length > 1 ? arguments : [undefined, arguments[0]];
      flux.setStateStatus(args[0], {
        dirty: !!args[1]
      });
    },

    setPathReadonly() {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments.length > 1 ? arguments : [undefined, arguments[0]];
      flux.setStateStatus(args[0], {
        readonly: !!args[1]
      });
    },

    setPathValue() {
      // eslint-disable-next-line prefer-rest-params
      // eslint-disable-next-line prefer-rest-params
      const args = arguments.length > 1 ? arguments : [undefined, arguments[0]];
      flux.setPathValue(args[0], args[1]);
    },

    setPathVisible() {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments.length > 1 ? arguments : [undefined, arguments[0]];
      flux.setStateStatus(args[0], {
        visible: !!args[1]
      });
    },

    resetValues(values) {
      flux.resetValues(values);
    }

  };
});