/**
 * // 校验
 validation(state: FormFieldState, allStates: FormPathStates) {},
 // 显示隐藏
 visible(state: FormFieldState, allStates: FormPathStates) {},
 */
export class FormAction {
  constructor(dispatcher, readonly = false) {
    this._dispatcher = dispatcher;
    this._readonly = readonly;
  }

  newForm(form, path, props, repo, handles) {
    const newProps = Object.assign({}, props);

    if (props.readonly == null) {
      // eslint-disable-next-line no-param-reassign
      newProps.readonly = this._readonly;
    }

    return this._dispatcher.dispatch({
      type: 'NEW_FORM',
      form,
      path,
      props: newProps,
      repo,
      handles
    });
  } // 手动设置状态


  updateProps(form, path, props, repo, handles) {
    this._dispatcher.dispatch({
      type: 'UPDATE_PROPS',
      form,
      path,
      props,
      repo,
      handles
    });
  }

  arrayMove(path, from, to) {
    this._dispatcher.dispatch({
      type: 'ARRAY_MOVE',
      path,
      from,
      to
    });
  } // value 改变


  changeValue(path, value) {
    this._dispatcher.dispatch({
      type: 'VALUE_CHANGE',
      path,
      value
    });
  } // 手动设置value


  setPathValue(path, value) {
    this._dispatcher.dispatch({
      type: 'SET_VALUE',
      path,
      value
    });
  }

  setReadonly(readonly) {
    this._dispatcher.dispatch({
      type: 'READONLY',
      readonly
    });
  }

  resetValues(values) {
    this._dispatcher.dispatch({
      type: 'RESET_VALUES',
      values
    });
  } // 手动设置 props


  setFieldProps(path, state) {
    this._dispatcher.dispatch({
      type: 'SET_STATE',
      path,
      state
    });
  } // 手动设置状态


  setStateStatus(path, state) {
    this._dispatcher.dispatch({
      type: 'SET_STATE',
      path,
      state
    });
  }

}