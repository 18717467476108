import React from 'react';
import { FormScopeProvider } from './context';
import { useFormState } from './use-form-state';
export const FormGroup = props => {
  var _a;

  const {
    name,
    label,
    children
  } = props;
  const {
    state,
    registry
  } = useFormState(props, 'group');
  const containerProps = registry.groupContainer ? Object.assign({
    label
  }, state) : undefined;
  const Container = (_a = registry.groupContainer) !== null && _a !== void 0 ? _a : React.Fragment;
  return React.createElement(FormScopeProvider, {
    name: name
  }, React.createElement(Container, Object.assign({}, containerProps), children));
};