import PubSub from 'pubsub-js';
import uuid2 from '../uuid';
export default class FormStore {
  constructor() {
    this._states = {};
    this.namespace = uuid2(8, 10);
  }

  newPath(path, state) {
    if (!this._states[path]) {
      this._states[path] = state;
      PubSub.publishSync(`${this.namespace}.${path}`, Object.assign({}, state));
    } else {
      console.error(`path \`${path}\`已存在`);
    }
  }

  movePath(fromPath, toPath) {
    if (this._states[fromPath]) {
      this._states[toPath] = this._states[fromPath];
      delete this._states[fromPath];
    }
  }

  getOne(path) {
    return this._states[path];
  }

  get(path) {
    if (path) {
      const os = Object.keys(this._states).reduce((states, npath) => npath === path || new RegExp(`^${path}[.\\[]`).test(npath) ? Object.assign(states, {
        [npath]: this._states[npath]
      }) : states, {});
      return Object.keys(os).length > 0 ? os : undefined;
    }

    return this._states;
  }

  set(path, data) {
    const states = this.get(path);

    if (!states) {
      console.error(`没有找到path \`${path}\``);
      return;
    }

    Object.keys(states).forEach(spath => {
      const keys = Object.keys(states[spath]);
      let trigger = false;
      keys.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const stateKey = key;
          states[spath][stateKey] = data[stateKey];
          trigger = true;
        }
      }); // eslint-disable-next-line no-unused-expressions

      trigger && PubSub.publishSync(`${this.namespace}.${spath}`, Object.assign({}, states[spath]));
    });
  }

  patchSet(states) {
    const keys = Object.keys(states);

    if (keys.length > 0) {
      keys.forEach(path => {
        if (this._states[path]) {
          Object.assign(this._states[path], states[path]);
          PubSub.publishSync(`${this.namespace}.${path}`, Object.assign({}, this._states[path]));
        }
      });
    }
  }

  setPathValue(path, value) {
    if (this._states[path]) {
      const keys = Object.keys(this._states[path]);
      let trigger = false;
      keys.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          const stateKey = key;
          this._states[path][stateKey] = value[stateKey];
          trigger = true;
        }
      }); // eslint-disable-next-line no-unused-expressions

      trigger && PubSub.publishSync(`${this.namespace}.${path}`, Object.assign({}, this._states[path]));
    } else {
      console.error(`没有找到path \`${path}\``);
    }
  }

  clear() {
    PubSub.unsubscribe(this.namespace);
  }

  subscribe() {
    const [path, subscriber] = // eslint-disable-next-line prefer-rest-params
    arguments.length > 1 ? arguments : [null, arguments[0]];

    if (path) {
      const initState = this.getOne(path);

      if (initState) {
        subscriber(path, Object.assign({}, initState));
      }
    }

    const token = PubSub.subscribe(`${this.namespace}${path ? `.${path}` : ''}`, (ns, state) => {
      subscriber(ns.replace(`${this.namespace}.`, ''), state);
    });
    return () => {
      PubSub.unsubscribe(token);
    };
  }

}