import { FormAction } from './form_action';
import { FormFieldState } from '../views/form_field';
import { FormDispatcher } from './form_dispatcher';
import FormStore from './form_store';
export class FormFlux extends FormAction {
  constructor(defaultValues, readonly = false) {
    const store = new FormStore();
    const dispatcher = new FormDispatcher(store, defaultValues, readonly);
    super(dispatcher);
    this.transforms = dispatcher.transforms;
    this._store = store;
    this.globalListener = null;
    this._paths = {};
  }

  getStates(path) {
    var _a;

    const states = (_a = this._store.get(path)) !== null && _a !== void 0 ? _a : {};
    const pathStates = Object.keys(states).reduce((acc, key) => {
      const state = states[key];

      if (state instanceof FormFieldState) {
        acc[key] = Object.assign({}, state);
      }

      return acc;
    }, {});
    return Object.keys(pathStates).length > 0 ? pathStates : undefined;
  }

  getTransformValue(path, value) {
    const transform = this.transforms[path];

    if (transform) {
      const tValue = transform(value);

      if (tValue && typeof tValue === 'object' && !Array.isArray(tValue)) {
        return tValue;
      }
    }

    return null;
  }

  addListener() {
    const [path, subscriber] = // eslint-disable-next-line prefer-rest-params
    arguments.length > 1 ? arguments : [null, arguments[0]];

    if (path) {
      if (this._paths[path]) {
        this._paths[path]();
      }

      this._paths[path] = this._store.subscribe(path, subscriber);
    } else {
      this.globalListener = this._store.subscribe(subscriber);
    }
  }

  unListener() {
    var _a; // eslint-disable-next-line prefer-rest-params


    const [path] = arguments;

    if (path) {
      const unsubscribe = this._paths[path];

      if (unsubscribe) {
        unsubscribe();
      } else {
        console.error(`没有找到path \`${path}\``);
      }
    } else {
      (_a = this.globalListener) === null || _a === void 0 ? void 0 : _a.call(this);
    }
  }

}