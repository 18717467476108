export const validateMessage = {
  required: ({
    label
  }) => `${label}不能为空！`,
  rule: ({
    label
  }) => `${label}格式不正确！`
}; // eslint-disable-next-line no-shadow

export var ValidStatusEnum;

(function (ValidStatusEnum) {
  ValidStatusEnum["ERROR"] = "error";
  ValidStatusEnum["SUCCESS"] = "success";
  ValidStatusEnum["PENDING"] = "pending";
})(ValidStatusEnum || (ValidStatusEnum = {}));