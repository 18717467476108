/**
 * 两数之间，即value不大于max，不小于min
 * @param value
 * @param min
 * @param max
 */
export const clamp = (value, min, max) => {
  return min < max ? value < min ? min : value > max ? max : value : value < max ? max : value > min ? min : value;
};
export const debounce = (func, wait = 10) => {
  let timeout;
  return function () {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this; // eslint-disable-next-line prefer-rest-params

    const args = arguments;
    if (timeout != null) clearTimeout(timeout);
    timeout = setTimeout(() => {
      // @ts-ignore
      func.apply(context, args);
    }, wait);
  };
};
export const padStart = function (str, targetLength, padString) {
  // 截断数字或将非数字转换为0
  targetLength = targetLength >> 0;
  padString = String(typeof padString !== 'undefined' ? padString : ' ');

  if (str.length > targetLength || padString === '') {
    return String(str);
  }

  targetLength = targetLength - str.length;

  if (targetLength > padString.length) {
    // 添加到初始值以确保长度足够
    padString += padString.repeat(targetLength / padString.length);
  }

  return padString.slice(0, targetLength) + String(str);
};