import creatFormHandles from './form_handles';
import { FormFlux } from './flux';
export * from './form_registry';
export { default as transformConfigs, isFormArray, isFormGroup, isCustomField } from './form_config';
export default class FormContext {
  constructor(defaultValues, readonly = false) {
    this.flux = new FormFlux(defaultValues, readonly);
    this.handles = creatFormHandles(this.flux);
  }

}