import { useMemo, useRef } from 'react';
export const usePickerData = (data, value) => {
  const previous = useRef([]);
  previous.current = useMemo(() => {
    var _a;

    if (Array.isArray(data) && previous.current.length > 0) {
      return previous.current;
    }

    const dv = previous.current.map((item, i) => {
      var _a;

      return (_a = item[value[i]]) === null || _a === void 0 ? void 0 : _a.value;
    });
    const pickerData = typeof data === 'function' ? data(dv) : (_a = [...data]) !== null && _a !== void 0 ? _a : [];
    return pickerData.map(columnData => columnData.map(item => typeof item === 'string' || typeof item === 'number' ? {
      label: item.toString(),
      value: item
    } : item));
  }, [data, value]);
  return previous.current;
};