import get from 'lodash.get';
import { FormFieldState } from '../views/form_field';
import { FormArrayState } from '../views/form_array';
import { FormGroupState } from '../views/form_group';
import formRules from '../form_rules';
import { ValidStatusEnum } from '../constant';
export class FormDispatcher {
  constructor(store, defaultValues, readonly = false) {
    this.transforms = {};
    this._validatorMap = {};
    this._visibleMap = {};
    this._propsMap = {};
    this._readonlyMap = {};
    this._depsSubscription = {};
    this.formConstructor = {
      array: FormArrayState,
      group: FormGroupState,
      field: FormFieldState
    };
    this._store = store;
    this._readonly = readonly;
    this._values = defaultValues;
  }
  /**
   * 缓存起来
   * @param rules
   * @param props
   * @param repo
   * @param path
   * @param handles
   * @private
   */


  handleProps({
    props,
    repo,
    path,
    handles
  }) {
    const state = {
      readonly: this._readonly
    };
    const {
      rules,
      visible = true,
      readonly,
      deps,
      label,
      asyncData,
      transform,
      required
    } = props;
    const validator = formRules(required, rules !== null && rules !== void 0 ? rules : [], {
      label
    }, handles, repo);

    if (validator) {
      this._validatorMap[path] = (nState, depsEffect = false) => {
        var _a;

        const currState = (_a = this._store.get(path)) !== null && _a !== void 0 ? _a : {};

        if ((validator.custom || !depsEffect) && currState.value !== nState.value) {
          return validator.validator(nState);
        }

        return null;
      };
    }

    if (transform) {
      if (typeof transform === 'string') {
        this.transforms[path] = value => ({
          [path]: get(value, transform)
        });
      } else if (typeof transform === 'function') {
        this.transforms[path] = transform;
      }
    }

    if (typeof visible === 'function') {
      this._visibleMap[path] = st => visible(st, handles);
    } else {
      state.visible = !!visible;
      this._visibleMap[path] = state.visible;
    }

    if (typeof readonly === 'function') {
      this._readonlyMap[path] = st => readonly(st, handles);
    } else {
      state.readonly = !!readonly;
      this._readonlyMap[path] = state.readonly;
    }

    if (typeof asyncData === 'function') {
      const async = asyncData();

      if (async instanceof Promise) {
        state.loading = true;
        async.then(data => {
          this._store.set(path, {
            loading: false,
            data
          });
        }).catch(() => {
          this._store.set(path, {
            loading: false
          });
        });
      }
    }

    if (typeof props.props === 'function') {
      this._propsMap[path] = st => props.props(st, handles);
    } else if (props.props && typeof props.props === 'object' && !Array.isArray(props.props)) {
      state.props = props.props;
      this._propsMap[path] = state.props;
    }

    if (deps && deps.length > 0) {
      deps.forEach(dep => {
        if (typeof dep === 'string') {
          if (!this._depsSubscription[dep]) {
            this._depsSubscription[dep] = [];
          }

          const index = this._depsSubscription[dep].findIndex(item => item.path === path);

          if (index > -1) {
            this._depsSubscription[dep][index] = {
              path
            };
          } else {
            this._depsSubscription[dep].push({
              path
            });
          }
        } else if (dep.path) {
          if (!this._depsSubscription[dep.path]) {
            this._depsSubscription[dep.path] = [];
          }

          const index = this._depsSubscription[dep.path].findIndex(item => item.path === path);

          if (index > -1) {
            this._depsSubscription[dep.path][index] = Object.assign(Object.assign({}, dep), {
              path
            });
          } else {
            this._depsSubscription[dep.path].push(Object.assign(Object.assign({}, dep), {
              path
            }));
          }
        }
      });
    }

    return state;
  }
  /**
   * 校验
   * @param path
   * @param state
   * @param depsEffect
   * @private
   */


  validateRule(path, state, depsEffect = false) {
    var _a;

    const validator = this._validatorMap[path];
    const newState = {};

    if (validator) {
      const result = validator(state, depsEffect);
      if (!result) return {};
      newState.errors = result.errors;

      if (result.async) {
        (_a = result.async) === null || _a === void 0 ? void 0 : _a.call(result, errs => {
          this._store.set(path, {
            errors: errs
          });
        });
      } else {
        newState.valid = result.errors.every(err => err.status === ValidStatusEnum.SUCCESS);
      }
    }

    return newState;
  }
  /**
   * state值变动是需要执行的逻辑
   * @param path
   * @param state
   * @param init 初始化调用（第一次）
   * @private
   */


  transformStateOnChange(path, state, init = false) {
    var _a;

    const storeState = (_a = this._store.getOne(path)) !== null && _a !== void 0 ? _a : {};
    const newState = Object.assign(Object.assign({}, state), {
      dirty: !init
    });
    const mergeState = Object.assign(Object.assign({}, storeState), state);

    if (init && this._values) {
      const value = get(this._values, path);

      if (value !== undefined) {
        newState.value = value;
        mergeState.value = value;
      }
    } // 规则校验


    Object.assign(newState, this.validateRule(path, mergeState)); // 显示隐藏

    const visibleTransform = this._visibleMap[path];

    if (typeof visibleTransform === 'function') {
      newState.visible = visibleTransform(mergeState);
    } // 只读模式


    const readonlyTransform = this._readonlyMap[path];

    if (typeof readonlyTransform === 'function') {
      newState.readonly = readonlyTransform(mergeState);
    }

    return newState;
  }

  changeDeps(path) {
    // 依赖
    const subscription = this._depsSubscription[path];

    if (subscription) {
      subscription.forEach(item => {
        this.depItemChange(item);
      });
    }
  } // 依赖值变动


  depItemChange({
    path,
    visible = true,
    readonly = true,
    rules = true,
    props = true
  }) {
    const state = this._store.getOne(path);

    if (!state) return;
    const newState = Object.assign({}, state); // 显示隐藏

    const visibleTransform = this._visibleMap[path];

    if (visible && visibleTransform) {
      if (typeof visibleTransform === 'function') {
        newState.visible = visibleTransform(state);
      }
    } // 只读模式


    const readonlyTransform = this._readonlyMap[path];

    if (readonly && readonlyTransform) {
      if (typeof readonlyTransform === 'function') {
        newState.readonly = readonlyTransform(state);
      }
    } // prop


    const propsTransform = this._propsMap[path];

    if (props && propsTransform) {
      if (typeof propsTransform === 'function') {
        newState.props = propsTransform(state);
      }
    }

    if (rules) {
      // 规则校验
      Object.assign(newState, this.validateRule(path, Object.assign({}, state), true));
    }

    this._store.set(path, newState);
  }

  dispatch(action) {
    var _a, _b, _c, _d; // eslint-disable-next-line default-case


    switch (action.type) {
      case 'NEW_FORM':
        {
          const state = new this.formConstructor[action.form](action.props);
          Object.assign(state, this.handleProps(action));
          const cstate = this.transformStateOnChange(action.path, state, true);
          Object.assign(state, cstate);
          const propsTransform = this._propsMap[action.path];

          if (typeof propsTransform === 'function') {
            ;
            state.props = propsTransform(state);
          }

          this._store.newPath(action.path, state);

          this.changeDeps(action.path);
          return Object.assign({}, state);
        }

      case 'READONLY':
        {
          this._readonly = action.readonly;
          const states = (_a = this._store.get()) !== null && _a !== void 0 ? _a : {};
          const ustates = {};
          Object.keys(states).forEach(path => {
            if (!this._readonly) {
              const readonlyTransform = this._readonlyMap[path];
              ustates[path] = {
                readonly: typeof readonlyTransform === 'function' ? readonlyTransform(states[path]) : readonlyTransform
              };
            } else {
              ustates[path] = {
                readonly: this._readonly
              };
            }
          });

          this._store.patchSet(ustates);

          break;
        }

      case 'VALUE_CHANGE':
        {
          const fieldState = this._store.getOne(action.path);

          if (fieldState instanceof FormFieldState) {
            // 依赖项
            this._store.set(action.path, this.transformStateOnChange(action.path, {
              value: (_b = action.value) !== null && _b !== void 0 ? _b : null
            }));

            this.changeDeps(action.path);
          }

          break;
        }

      case 'SET_STATE':
        {
          this._store.set(action.path, action.state);

          break;
        }

      case 'RESET_VALUES':
        {
          if (action.values) {
            this._values = action.values;
          }

          const states = this._store.get();

          const patchStates = {};
          Object.keys(states).forEach(path => {
            var _a, _b;

            const value = (_b = (_a = this._values) === null || _a === void 0 ? void 0 : _a[path]) !== null && _b !== void 0 ? _b : null;

            if (states[path].value !== value) {
              patchStates[path] = this.transformStateOnChange(path, {
                value
              }, true);
            }
          });

          this._store.patchSet(patchStates);

          Object.keys(patchStates).forEach(path => {
            this.changeDeps(path);
          });
          break;
        }

      case 'SET_VALUE':
        {
          const states = this._store.get(action.path);

          if (!states) {
            console.error(`没有找到path \`${action.path}\``);
            break;
          }

          const patchStates = {};
          Object.keys(states).forEach(spath => {
            const state = states[spath];

            if (state instanceof FormFieldState) {
              // eslint-disable-next-line no-nested-ternary
              const npath = !action.path ? spath : action.path !== spath ? spath.replace(new RegExp(`^${action.path}\\.?`), '') : null;
              patchStates[spath] = {};
              const newValue = npath ? get(action.value, npath) : action.value;

              if (newValue !== undefined) {
                Object.assign(patchStates[spath], this.transformStateOnChange(spath, {
                  value: newValue
                }));
              }
            }
          });

          this._store.patchSet(patchStates);

          Object.keys(patchStates).forEach(path => {
            this.changeDeps(path);
          });
          break;
        }

      case 'ARRAY_MOVE':
        {
          const {
            path,
            to
          } = action;
          let {
            from
          } = action;
          if (from === to) break;
          const mark = from > to ? -1 : 1;

          while (from !== to) {
            const fromPath = `${path}.${from + mark}`;
            const states = (_c = this._store.get(fromPath)) !== null && _c !== void 0 ? _c : {}; // eslint-disable-next-line no-loop-func

            Object.keys(states).forEach(key => {
              this._store.movePath(key, key.replace(fromPath, `${path}.${from}`));
            });
            from += mark;
          }

          break;
        }

      case 'UPDATE_PROPS':
        {
          const newState = this.transformStateOnChange(action.path, this.handleProps(action));
          const state = (_d = this._store.getOne(action.path)) !== null && _d !== void 0 ? _d : {};
          const ustate = Object.keys(newState).reduce((acc, key) => {
            if (state[key] !== newState[key]) {
              acc[key] = newState[key];
            }

            return acc;
          }, {});

          if (Object.keys(ustate).length > 0) {
            this._store.set(action.path, ustate);
          }

          this.changeDeps(action.path);
          break;
        }
    }

    return null;
  }

}