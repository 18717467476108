import React, { forwardRef, useCallback, useRef } from 'react';
import { Picker } from './picker';
import { padStart } from './utils';

const getYears = () => {
  const now = new Date().getFullYear();
  const min = now - 100;
  return new Array(200).fill(0).map((_, i) => (min + i).toString());
};

const getMonths = () => {
  return new Array(12).fill(0).map((_, i) => padStart((i + 1).toString(), 2, '0'));
};

const getDays = (year = new Date().getFullYear(), month = new Date().getMonth() + 1) => {
  const daysLen = new Date(year, month, 0).getDate();
  return new Array(daysLen).fill(0).map((_, i) => padStart((i + 1).toString(), 2, '0'));
};

const getHours = () => {
  return new Array(24).fill(0).map((_, i) => padStart(i.toString(), 2, '0'));
};

const getMinutes = () => {
  return new Array(59).fill(0).map((_, i) => padStart((i + 1).toString(), 2, '0'));
};

const getSeconds = () => {
  return new Array(59).fill(0).map((_, i) => padStart((i + 1).toString(), 2, '0'));
};

const getFormatFunc = format => {
  const result = {
    func: [],
    units: []
  };
  const fnc = {
    'y+': [getYears, {
      width: 68,
      value: '年'
    }],
    'M+': [() => getMonths(), {
      width: 52,
      value: '月'
    }],
    'd+': [getDays, {
      width: 52,
      value: '日'
    }],
    'H+': [() => getHours(), {
      width: 56,
      value: '时'
    }],
    'm+': [() => getMinutes(), {
      width: 56,
      value: '分'
    }],
    's+': [() => getSeconds(), {
      width: 56,
      value: '秒'
    }]
  };

  for (const k in fnc) {
    if (new RegExp('(' + k + ')').test(format)) {
      result.func.push(fnc[k][0]);
      result.units.push(fnc[k][1]);
    }
  }

  return result;
};

export function formatDate(value, fmt) {
  const regs = ['y+', 'M+', 'd+', 'H+', 'm+', 's+'];
  let i = 0;
  regs.forEach(reg => {
    if (new RegExp('(' + reg + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, value[i]);
      i++;
    }
  });
  return fmt;
}

function parseDate(dateStr, fmt) {
  const date = dateStr == null ? new Date() : new Date(dateStr.replace(/-/g, '/'));
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds() // 秒

  };
  const value = [];

  if (/(y+)/.test(fmt)) {
    value.push(date.getFullYear().toString().substr(4 - RegExp.$1.length));
  }

  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      value.push(padStart(o[k].toString(), 2, '0'));
    }
  }

  return value;
}

export const DatePicker = forwardRef(({
  value,
  format = 'yyyy-MM-dd HH:mm:ss',
  onSelected
}, ref) => {
  const fmtColFns = useRef(getFormatFunc(format));
  const columns = useCallback(value => {
    return fmtColFns.current.func.map(item => item(...value));
  }, [fmtColFns]);
  const onSelectedChange = useCallback(value => {
    onSelected === null || onSelected === void 0 ? void 0 : onSelected(formatDate(value, format));
  }, [onSelected]);
  return React.createElement(Picker, {
    data: columns,
    value: parseDate(value, format),
    units: fmtColFns.current.units,
    onSelected: onSelectedChange
  });
});