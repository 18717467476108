export class FormGroupState {
  constructor({
    defaultValues,
    visible,
    readonly
  }) {
    this.value = {};
    this.dirty = false;
    this.valid = false;
    this.visible = false;
    this.readonly = false;
    this.errors = [];
    this.value = defaultValues && typeof defaultValues === 'object' && !Array.isArray(defaultValues) ? defaultValues : {};
    this.visible = typeof visible === 'function' ? false : !!visible;
    this.readonly = typeof readonly === 'function' ? false : !!readonly;
  }

}