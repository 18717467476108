import React, { useMemo } from 'react';
export const FormRepoContext = React.createContext({});
export const FormGlobalContext = React.createContext({});
export const FormScopeContext = React.createContext({}); // 作用域 构建path

export const FormScopeProvider = ({
  name,
  children
}) => {
  const {
    path = []
  } = React.useContext(FormScopeContext);
  const value = useMemo(() => ({
    path: name !== undefined && name !== null && name !== '' ? [...path, name.toString()] : path
  }), [name, path]);
  return React.createElement(FormScopeContext.Provider, {
    value: value
  }, children);
};