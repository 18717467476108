export class FormFieldState {
  constructor({
    defaultValue,
    visible = true,
    readonly = false,
    rules,
    props = {}
  }) {
    this.value = null; // 异步数据请求

    this.loading = false; // 改变视图值：true

    this.dirty = false; // 校验通过：true

    this.valid = false; // 隐藏：false

    this.visible = false; // 只读模式

    this.readonly = false; // 正在执行异步验证：true

    this.pending = false; // 触发blur事件：true

    this.touched = false; // 校验未通过message

    this.errors = []; // 渲染项的props

    this.props = {};
    this.value = defaultValue !== null && defaultValue !== void 0 ? defaultValue : null;
    this.visible = typeof visible === 'function' ? false : !!visible;
    this.readonly = typeof readonly === 'function' ? false : !!readonly;
    this.valid = !rules || rules.length === 0;
    this.props = typeof props === 'function' ? {} : props;
  }

}