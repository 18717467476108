import get from 'lodash.get';
import { ValidStatusEnum } from './constant';

const isTypeRule = rule => !!rule.type || typeof rule === 'string'; // 类型规则转成自定义规则校验


const typeRuleToCustomRule = (rule, repo) => {
  var _a, _b, _c, _d;

  if (!repo.formRules) return null;
  const defaultMessage = (_a = repo.validateMessage) === null || _a === void 0 ? void 0 : _a.rule;

  if (typeof rule === 'string') {
    const typeRule = repo.formRules[rule];

    if (!typeRule) {
      console.error(`校验规则类型 \`${rule}\` 没有找到！`);
      return null;
    }

    return {
      validator: typeRule.validator,
      message: (_b = typeRule.message) !== null && _b !== void 0 ? _b : defaultMessage
    };
  }

  if (typeof rule.type === 'string') {
    const typeRule = repo.formRules[rule.type];

    if (!typeRule) {
      console.error(`校验规则类型 \`${rule.type}\` 没有找到！`);
      return null;
    }

    return {
      validator: typeRule.validator,
      message: (_d = (_c = rule.message) !== null && _c !== void 0 ? _c : typeRule.message) !== null && _d !== void 0 ? _d : defaultMessage
    };
  }

  return null;
};

const transformRules = (params, rules, handles, repo) => {
  return rules.reduce((acc, rule) => {
    const handleRule = isTypeRule(rule) ? typeRuleToCustomRule(rule, repo) : rule;

    if (handleRule && (handleRule.validator instanceof RegExp || typeof handleRule.validator === 'function')) {
      const {
        validator,
        message,
        transform
      } = handleRule;
      const newRule = {
        validator,
        message,
        custom: false
      };

      if (validator instanceof RegExp) {
        newRule.validator = state => validator.test(state.value);
      } else if (typeof validator === 'function') {
        newRule.validator = state => validator(state, handles);

        newRule.custom = true;
      }

      if (typeof message === 'function') {
        newRule.message = message(params);
      }

      if (transform) {
        if (typeof transform === 'string') {
          newRule.transform = value => get(value, transform);
        } else if (typeof transform === 'function') {
          newRule.transform = transform;
        }
      }

      acc.push(newRule);
    }

    return acc;
  }, []);
}; // rules 生成两份一份存异步，一份存同步


const computedRules = (rules, state, asyncFlag) => {
  const allAsync = [];
  const errors = rules.map((rule, i) => {
    var _a;

    const {
      validator,
      message,
      transform
    } = rule;

    if (!validator) {
      return {
        message: (_a = rules === null || rules === void 0 ? void 0 : rules[i]) === null || _a === void 0 ? void 0 : _a.message,
        status: ValidStatusEnum.PENDING
      };
    }

    const result = validator(Object.assign(Object.assign({}, state), {
      value: transform ? transform(state.value) : state.value
    }));
    let status = result ? ValidStatusEnum.SUCCESS : ValidStatusEnum.ERROR;

    if (asyncFlag && result instanceof Promise) {
      allAsync[i] = Object.assign({}, rule);
      status = ValidStatusEnum.PENDING; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign

      rules[i].validator = null;
    }

    return {
      message,
      status
    };
  });
  return {
    errors,
    async: allAsync.length > 0 ? astate => Promise.all(allAsync.map(aRule => aRule ? aRule.validator(Object.assign(Object.assign({}, astate), {
      value: aRule.transform ? aRule.transform(astate.value) : astate.value
    })) : Promise.resolve(true))).then(data => data.map((item, i) => {
      return item === undefined ? astate.errors[i] : {
        message: rules[i].message,
        status: item ? ValidStatusEnum.SUCCESS : ValidStatusEnum.ERROR
      };
    })) : undefined
  };
};

export default ((required, rules, params, handles, repo) => {
  var _a, _b, _c; // 必填项


  let requiredRule;
  const index = rules.findIndex(item => item.type === 'required');

  if (index > -1) {
    requiredRule = rules[index];
    rules.splice(index, 1);
  }

  if (required && !requiredRule) {
    requiredRule = {
      validator: ({
        value
      }) => Array.isArray(value) && value.length > 0 || !!value || value === 0,
      message: (_b = (_a = repo.validateMessage) === null || _a === void 0 ? void 0 : _a.required) !== null && _b !== void 0 ? _b : `${(_c = params.label) !== null && _c !== void 0 ? _c : '该项'}不能为空`
    };
  }

  if (requiredRule) rules.unshift(requiredRule);
  const handleRules = transformRules(Object.assign({
    label: '该项'
  }, params), rules, handles, repo);
  let deferTimer;
  let asyncValidator;
  let async = null;
  return handleRules && handleRules.length > 0 ? {
    custom: handleRules.some(item => item.custom),
    validator: state => {
      const cRule = computedRules(handleRules, state, !asyncValidator);

      if (!asyncValidator) {
        asyncValidator = cRule.async;
      }

      if (asyncValidator) {
        async = callback => {
          if (deferTimer) {
            clearTimeout(deferTimer);
            deferTimer = null;
          }

          deferTimer = setTimeout(() => {
            asyncValidator(Object.assign(Object.assign({}, state), {
              errors: cRule.errors
            })).then(callback);
          }, 500);
        };
      }

      return {
        errors: cRule.errors,
        async
      };
    }
  } : null;
});