import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Easing, PanResponder, StyleSheet, Text, View } from 'react-native';
import { A } from './constant';
import { clamp, debounce } from './utils';
export const PickerColumn = ({
  data = [],
  value,
  itemHeight,
  itemShowCount,
  itemColor = '#444',
  itemFontSize = 15,
  itemFontFamily,
  onSelected,
  virtualized = true
}) => {
  const _this = useRef({
    viewHeight: itemShowCount * itemHeight,
    cacheData: [],
    animatedValue: new Animated.Value(Math.floor(itemShowCount / 2) * itemHeight),
    offsetY: Math.floor(itemShowCount / 2) * itemHeight,
    startOffsetY: 0,
    minOffsetY: -itemHeight * (data.length - Math.floor(itemShowCount / 2) - 1),
    maxOffsetY: itemHeight * Math.floor(itemShowCount / 2),
    onSelected
  }).current;
  const [renderItemData, setRenderItemData] = useState([]);
  _this.onSelected = onSelected;
  useEffect(() => {
    _this.cacheData = data.map((item, index) => Object.assign(Object.assign({}, item), {
      index
    }));
    _this.minOffsetY = -itemHeight * (data.length - 4);
    computedRenderData();

    _this.animatedValue.setValue(clamp(_this.offsetY, _this.minOffsetY, _this.maxOffsetY));
  }, [data]);
  useEffect(() => {
    if (!value) return;
    const offsetY = -itemHeight * (value - 3);

    if (offsetY !== _this.offsetY) {
      _this.offsetY = offsetY;

      _this.animatedValue.setValue(clamp(offsetY, _this.minOffsetY, _this.maxOffsetY));
    }
  }, [value]);
  useEffect(() => {
    _this.animatedValue.addListener(({
      value: offsetY
    }) => {
      _this.offsetY = offsetY;

      if (virtualized) {
        computedRenderData();
      }
    });
  }, []);
  const onEnd = useCallback((e, {
    vy
  }) => {
    const offset = computedRepairBoundary(_this.offsetY);
    let value = clamp(offset, _this.minOffsetY, _this.maxOffsetY);
    let duration = 100;
    let easing = Easing.bezier(0.165, 0.84, 0.44, 1);

    if (Math.abs(vy) > 0.6 && value === offset) {
      duration = 1500;
      easing = Easing.bezier(0.17, 0.89, 0.45, 1);
      value = clamp(computedRepairBoundary(offset + vy / A), _this.minOffsetY, _this.maxOffsetY);
    }

    startAnimated(value, duration, easing);
  }, []);
  const startAnimated = useCallback((value, duration, easing) => {
    Animated.timing(_this.animatedValue, {
      duration,
      toValue: value,
      useNativeDriver: true,
      easing
    }).start(() => {
      const halfHeight = _this.viewHeight / 2;

      _this.onSelected(Math.floor((halfHeight - _this.offsetY) / itemHeight));
    });
  }, []);
  const computedRepairBoundary = useCallback(value => {
    return Math.round(value / itemHeight) * itemHeight;
  }, [itemHeight]);
  const computedRenderData = useCallback(debounce(() => {
    let index = Math.ceil(-_this.offsetY / itemHeight) - 1;

    if (index > 0) {
      const subIndex = _this.cacheData.length - index;
      index = subIndex >= itemShowCount ? index : _this.cacheData.length - itemShowCount;
      setRenderItemData(_this.cacheData.slice(index, index + itemShowCount + 1));
    } else {
      setRenderItemData(_this.cacheData.slice(0, itemShowCount + 1));
    }
  }), []);
  const panResponder = useRef(PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    onStartShouldSetPanResponderCapture: () => true,
    onMoveShouldSetPanResponderCapture: () => true,
    onPanResponderGrant: () => {
      _this.animatedValue.stopAnimation();

      _this.startOffsetY = _this.offsetY;
    },
    onPanResponderMove: (e, {
      dy
    }) => {
      _this.animatedValue.setValue(dy + _this.startOffsetY);
    },
    onPanResponderRelease: onEnd
  })).current;
  const textStyle = StyleSheet.flatten({
    fontFamily: itemFontFamily,
    fontSize: itemFontSize,
    color: itemColor
  });
  const contentStyle = StyleSheet.flatten([{
    height: itemHeight * data.length
  }, styles.content]);
  const itemStyle = StyleSheet.flatten([styles.row, {
    height: itemHeight
  }]);
  return React.createElement(View, Object.assign({}, panResponder.panHandlers, {
    style: styles.container
  }), React.createElement(Animated.View, {
    style: [contentStyle, {
      transform: [{
        translateY: _this.animatedValue
      }]
    }]
  }, renderItemData.map(({
    label,
    index
  }) => React.createElement(View, {
    key: index,
    style: [itemStyle, {
      top: itemHeight * index
    }]
  }, React.createElement(Text, {
    style: textStyle
  }, label.toString().length > 3 ? `${label}  ` : label)))));
};
const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1
  },
  content: {
    position: 'relative'
  },
  row: {
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
});