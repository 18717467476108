var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { DEFAULT_ITEM_HEIGHT, DEFAULT_ITEM_SHOW_COUNT } from './constant';
import { PickerColumn } from './column';
import { LinearGradient } from 'expo-linear-gradient';
import { usePickerData } from './use-picker-data';
import { debounce } from './utils';
export const Picker = _a => {
  var {
    data,
    style,
    itemHeight = DEFAULT_ITEM_HEIGHT,
    itemShowCount = DEFAULT_ITEM_SHOW_COUNT,
    itemColor = '#444',
    itemFontSize = 15,
    onSelected,
    value,
    units
  } = _a,
      props = __rest(_a, ["data", "style", "itemHeight", "itemShowCount", "itemColor", "itemFontSize", "onSelected", "value", "units"]);

  const [selectedIndex, setSelectedIndex] = useState([]);
  const [columnWidth, setColumnWidth] = useState(0);
  const columns = usePickerData(data, selectedIndex);
  const maskHeight = useRef(Math.floor(itemShowCount / 2) * itemHeight).current;
  useEffect(() => {
    if (selectedIndex.length !== columns.length) {
      setSelectedIndex(columns.map(() => 0));
    } else {
      // 调整selectedIndex的最大最小值
      const selected = columns.map((col, i) => col.length <= selectedIndex[i] ? col.length - 1 : selectedIndex[i]);

      if (selected.toString() !== selectedIndex.toString()) {
        setSelectedIndex(selected);
      }

      if (typeof data === 'function') {
        handleSelected(selected.map((item, i) => {
          var _a;

          return (_a = columns[i][item]) === null || _a === void 0 ? void 0 : _a.value;
        }));
      }
    }
  }, [columns]);
  useEffect(() => {
    if (value && columns.length === value.length) {
      const index = columns.map((column, i) => column.findIndex(item => value[i] === item.value));
      index.toString() !== selectedIndex.toString() && setSelectedIndex(index);
    }
  }, [value]);
  const handleSelected = useCallback(debounce(value => {
    onSelected === null || onSelected === void 0 ? void 0 : onSelected(value);
  }, 300), [onSelected]);
  const onColumnSelected = useCallback(function (index, value) {
    selectedIndex[index] = value;
    const selected = [...selectedIndex];
    setSelectedIndex(selected); // data类型是数组直接调用选中回调，非单独处理

    if (Array.isArray(data)) {
      handleSelected(selected.map((item, i) => {
        var _a;

        return (_a = columns[i][item]) === null || _a === void 0 ? void 0 : _a.value;
      }));
    }
  }, [columns]);
  const onGetWidth = useCallback(e => {
    setColumnWidth(e.nativeEvent.layout.width / columns.length);
  }, []);
  const renderColumns = useMemo(() => columns.map((item, i) => {
    return React.createElement(PickerColumn, Object.assign({
      key: i
    }, props, {
      value: selectedIndex[i],
      data: item,
      itemColor: itemColor,
      itemHeight: itemHeight,
      itemFontSize: itemFontSize,
      itemShowCount: itemShowCount,
      onSelected: onColumnSelected.bind(null, i)
    }));
  }), [columns, value]);
  const renderGradient = useCallback((colors = ['#fff', '#ffffff80']) => React.createElement(LinearGradient, {
    style: {
      height: maskHeight
    },
    colors: colors,
    start: {
      x: 0,
      y: 0
    },
    end: {
      x: 0,
      y: 1
    }
  }), []);
  const renderUnits = useMemo(() => React.createElement(View, {
    style: [styles.selected, {
      height: itemHeight
    }]
  }, units === null || units === void 0 ? void 0 : units.map((unit, i) => {
    var _a;

    return React.createElement(View, {
      style: styles.unit,
      key: i
    }, React.createElement(Text, {
      style: [styles.unitText, {
        color: itemColor,
        fontSize: itemFontSize,
        width: unit.width != null ? unit.width : columnWidth
      }]
    }, (_a = unit.value) !== null && _a !== void 0 ? _a : unit));
  })), [columnWidth]);
  return React.createElement(View, {
    style: [styles.body, {
      height: itemHeight * itemShowCount
    }]
  }, React.createElement(View, {
    style: styles.container,
    onLayout: onGetWidth
  }, renderColumns), React.createElement(View, {
    style: styles.mask,
    pointerEvents: 'none'
  }, renderGradient(), renderUnits, renderGradient(['#ffffff80', '#fff'])));
};
const absolute = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0
};
const styles = StyleSheet.create({
  body: {
    position: 'relative'
  },
  container: Platform.select({
    default: Object.assign({
      overflow: 'hidden',
      flexDirection: 'row'
    }, absolute),
    web: Object.assign({
      userSelect: 'none',
      overflow: 'hidden',
      flexDirection: 'row'
    }, absolute)
  }),
  mask: absolute,
  selected: {
    flexDirection: 'row'
  },
  unit: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 4
  },
  unitText: {
    textAlign: 'right'
  }
});